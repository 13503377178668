<template>
  <div class="home-course-box">
    <div class="home-course-type">
      <div>
        <span>报名状态：</span>
        <div>
          <span :class="{'active':processStatus == ''}" @click="activityStatus('')">全部</span>
          <span :class="{'active':processStatus == '1'}" @click="activityStatus('1')">待开始</span>
          <span :class="{'active':processStatus == '2'}" @click="activityStatus('2')">进行中</span>
          <span :class="{'active':processStatus == '3'}" @click="activityStatus('3')">已结束</span>
        </div>
      </div>
      <div>
        <span>活动类型：</span>
        <div>
          <span :class="{'active':activityTypeId == ''}" @click="activityType('')">全部</span>
          <span v-for="item in dict.ACTIVITY_TYPE" :key="item.id" :class="{'active':activityTypeId == item.itemValue}" @click="activityType(item.itemValue)">{{item.itemText}}</span>
        </div>
      </div>
    </div>
    <div class="home-course-filter">
      <div>
        <span :class="{'active':activitySortVal == ''}" @click="activitySort('')">综合排序</span>
        <span :class="{'active':activitySortVal == 'viewed'}" @click="activitySort('viewed')">人气优先</span>
        <span :class="{'active':activitySortVal == 'published_time'}" @click="activitySort('published_time')">时间优先</span>
      </div>
      <a-input-search
        placeholder="搜索关键词"
        @search="onSearch"
        style="width: 320px"
        v-model="activityName"
      >
        <a-button slot="enterButton"><a-icon type="search" /></a-button>
      </a-input-search>
    </div>
    <div :class="{'loading-style':loading}">
      <a-skeleton avatar :loading="loading" :paragraph="{ rows: 10 }">
        <div class="home-course-list" v-if="activityList != ''">
          <div v-for="(item, index) in activityList" :key="index" @click="activityInfo(item.id)">
            <div class="home-course-list-img">
              <span class="bg3" v-if="item.activityEnrollStatus == 1">报名待开始</span>
              <span class="bg1" v-if="item.activityEnrollStatus == 2">报名进行中</span>
              <span class="bg2" v-if="item.activityEnrollStatus == 3">报名已结束</span>
              <v-img :attachment-id="item.activityCoverAttmId" :default-img="require('@/assets/img/activity/cmrt.png')" />
            </div>
            <div class="home-course-list-info">
              <span>{{item.activityName}}</span>
              <div>
                <p><img alt="图片" src="@/assets/img/activity/shijian.png" /> {{item.enrollStartTime | moment('YYYY/MM/DD')}} - {{item.enrollEndTime | moment('YYYY/MM/DD')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="padding:100px 0;display:block">
          <a-empty />
        </div>
      </a-skeleton>
    </div>
    <pagination
      :total="page.total"
      :current="page.current"
      :size="page.size"
      @changes="pageChange"
    />
  </div>
</template>

<script>
import { getActivityList } from "@/api/activity";
import VImg from "@/components/VImg";
import pagination from "../components/pagination.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components:{
    pagination,
    VImg
  },
  data() {
    return {
      activityList: [],
      page: {
        current: 1,
        size: 6,
        total: 0,
      },
      activitySortVal:'',
      activityName:'',
      activityTypeId:'',
      processStatus:'',
      loading:false
    };
  },
  computed:mapGetters(["dict"]),
  mounted(){
    this.LoadDictData(["ACTIVITY_TYPE"]);
    this.loadGetActivityList()
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadGetActivityList(){
      let {page,activityName,activitySortVal,activityTypeId,processStatus,activityList} = this
      let data = {
        activityName,
        orderField:activitySortVal,
        ascOrDesc:'desc',
        activityTypeId,
        activityEnrollStatus:processStatus
      }
      if(activityList == ''){
        this.loading = true
      }
      getActivityList(page,data).then((res)=>{
        this.activityList = res.records
        this.page.total = res.total
        this.loading = false
      })
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetActivityList();
    },
    // 排序
    activitySort(val){
      this.activitySortVal = val
      this.loadGetActivityList();
    },
    activityType(id){
      this.page.current = 1
      this.activityTypeId = id
      this.loadGetActivityList();
    },
    activityStatus(id){
      this.page.current = 1
      this.processStatus = id
      this.loadGetActivityList();
    },
    onSearch() {
      this.loadGetActivityList();
    },
    activityInfo(id) {
      this.$router.push({
        path: "/activity/activityInfo",
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-course-box {
  .home-course-type {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    margin-bottom: 16px;
    >div{
      display: flex;
      > span {
        white-space: nowrap;
        line-height: 32px;
      }
      > div {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        > span {
          min-width: 80px;
          padding: 0 16px;
          height: 32px;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          cursor: pointer;
          text-align: center;
          line-height: 30px;
          margin: 0 16px 12px 0;
          &.active {
            border-color: #EA0B06;
            color: #EA0B06;
          }
        }
      }
    }
  }
  .home-course-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      > span {
        color: #8c8c8c;
        cursor: pointer;
        margin-right: 32px;
        &.active {
          color: #EA0B06;
        }
      }
    }
  }
  .home-course-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    > div {
      width: 410px;
      margin: 24px 24px 0 0;
      transition: all 0.3s;
      background: #fff;
      cursor: pointer;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .home-course-list-img {
        position: relative;
        height: 180px;
        > span {
          position: absolute;
          top: 8px;
          left: 0;
          width: 88px;
          height: 24px;
          color: #fff;
          line-height: 24px;
          text-align: center;
          &.bg1{
            background: url(../../assets/img/activity/hdicon.png) no-repeat;
            background-size: 100% 100%;
          }
          &.bg2{
            background: url(../../assets/img/activity/hdicon2.png) no-repeat;
            background-size: 100% 100%;
          }
          &.bg3{
            background: url(../../assets/img/activity/hdicon3.png) no-repeat;
            background-size: 100% 100%;
          }
        }
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .home-course-list-info {
        padding: 16px 16px 24px;
        > span {
          display: block;
          line-height: 14px;
          margin-bottom: 22px;
          transition: all 0.3s;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > div {
          display: flex;
          justify-content: space-between;
          >p{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #8c8c8c;
            >img{
              margin-right: 8px;
            }
          }
          > span {
            padding: 0 8px;
            height: 20px;
            border: 1px solid #EA0B06;
            border-radius: 2px;
            color: #EA0B06;
            line-height: 18px;
            font-size: 12px;
          }
        }
      }
      &:hover {
        box-shadow: 0px 4px 24px 0px rgba(0, 65, 152, 0.08);
        .home-course-list-info {
          > span {
            color: #EA0B06;
          }
        }
      }
    }
  }
  .home-course-page {
    text-align: center;
  }
}
</style>
